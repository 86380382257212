<template>
    <div class="consti-dossier-section-read-item input-group mb-3">
        <div class="input-group-prepend pointer">
            <span class="input-group-text input-icon input-green-background"><img
                src="@/assets/images/checkbox.svg"></span>
        </div>
        <input type="text" readonly class="form-control input-constituer-dossier shadow-none"
            :placeholder="document.label" :aria-label="document.label"
            aria-describedby="basic-addon1" @click="onClickDocument()">
        <span class="input-group-text input-prev-gr pointer icon" @click="onClickDocument()">
            <img src="@/assets/images/previsualisation-gr.svg">
            <span class="tooltip-text">Afficher les documents</span>
        </span>
        <span class="input-group-text input-prev-gr pointer icon" @click="openAddDocument()">
            <img src="@/assets/images/plus.png">
            <span class="tooltip-text">Ajouter des documents</span>
        </span>
    </div>
</template>

<script>
import emitter from '../../events/emitter.js';
import events from '../../constants/events';
import Document from '../../models/document';

export default {
    props: {
        document: Document
    },
    methods: {
        onClickDocument() {
            emitter.emit(events.document.reader.show, {
                document: this.document,
                editable: false
            });
        },
        openAddDocument() {
            emitter.emit('show-upload-popup', {document: this.document, startWithUploadDocument: true });
        }
    },
}
</script>

<style scoped lang="scss">
.consti-dossier-section-read{
    cursor: pointer;
}
.icon {
    position: relative;
    &:hover {
        img {
            filter: brightness(0.8);
        }

        .tooltip-text {
            visibility: visible;
        }
    }

    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: #F2F2F2;
        filter: brightness(0.9);
        text-align: center;
        border-radius: 50px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 90%;
        left: 50%;
        margin-left: -100px;
    }
}
</style>
