<template>
  <MainLayout
    :content_title="content_title"
    :toBlur="toBlur">
    <template v-slot:default>
      <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
        <BlckTransmitDoc @showUploadDocumentPopup="onShowShowUploadPopup" />
      </div>
      <div class="col-12 col-lg-6 col-xl-4">
        <BlckAlgoan/>
        <BlckProgress :showAccess="false"/>
      </div>
      <BlckSecurityInfoSpan />
    </template>
    <template v-slot:modals>
        <div v-if="displayUploadPopup">
          <UploadFile @showUploadDocumentPopup="onShowShowUploadPopup" :document="documentToUpload" :startWithUploadDocument="startWithUploadDocument" />
        </div>
        <DocumentReader />
    </template>
  </MainLayout>
</template>

<script>
import store from "@/store";
import emitter from '../events/emitter.js';
import BlckTransmitDoc from "../components/Consti-Dossier/BlckTransmitDoc.vue";
import BlckSecurityInfoSpan from "../components/Consti-Dossier/BlckSecurityInfoSpan.vue";
import DocumentReader from '../components/Popups/DocumentReader.vue';
import UploadFile from "../components/Popups/UploadFile.vue";
//import Document from '../models/document.js';
import MainLayout from "../layouts/main.layout";
import BlckProgress from "../components/Accueil/BlckProgress.vue";
import { LOGIN_PATH } from "../router/pathes";
import BlckAlgoan from "@/components/Consti-Dossier/BlckAlgoan";

export default {
  page: {
    title: "Home",
    meta: [{ name: "Home page"}],
  },
  components: {
    BlckAlgoan,
    MainLayout,
    BlckTransmitDoc,
    BlckSecurityInfoSpan,
    DocumentReader,
    UploadFile,
    BlckProgress,
  },
  data() {
    return {
      content_title : "MES DOCUMENTS",
      displayUploadPopup : false,
      toBlur : false,
      documentToUpload: null, // Document
      user: store.user,
      startWithUploadDocument: false
    };
  },
  mounted() {
    emitter.on('show-upload-popup', (event) => {
      this.documentToUpload = event.document;
      this.displayUploadPopup = true;
      this.startWithUploadDocument = event.startWithUploadDocument || false;
      document.body.classList.add("no-scroll");
    });
    emitter.on('close-upload-popup', () => {
      this.displayUploadPopup = false;
      document.body.classList.remove("no-scroll");
    });
  },
  beforeUnmount() {
    emitter.off("show-upload-popup");
    emitter.off("close-upload-popup");
  },
  methods: {
    onShowShowUploadPopup(value){
      this.displayUploadPopup = value
      this.toBlur = value
    },
    logOut() {
      store.commit("setIsConnected", false);
      this.$router.push(LOGIN_PATH);
    },
  },
};
</script>
<style>
body.no-scroll {
  overflow-y: hidden !important;
  height: 100%;
  width: 100%;
}
</style>
